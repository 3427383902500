import React, { useState } from "react";
import { useSelector } from "react-redux";

export const Gallery = () => {
  const [getType, setType] = useState("Prewed");
  const gallaryFlag = useSelector((state) => state.gallery.galleryType);
  console.log("gallaryFlag: ", gallaryFlag);

  return (
    <main id="main" data-aos="fade" data-aos-delay="1500">
      <div className="page-header d-flex align-items-center">
        <div className="container position-relative">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-6 text-center">
              <h2>Nature (16 images)</h2>
              <p>
                Odio et unde deleniti. Deserunt numquam exercitationem. Officiis
                quo odio sint voluptas consequatur ut a odio voluptatem. Sit
                dolorum debitis veritatis natus dolores. Quasi ratione sint. Sit
                quaerat ipsum dolorem.
              </p>

              <a className="cta-btn" href="contact.html">
                Available for hire
              </a>
            </div>
          </div>
        </div>
      </div>
      <section id="gallery" className="gallery">
        <div className="container-fluid">
          {gallaryFlag === 0 && (
            <div className="row gy-4 justify-content-center">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="gallery-item h-100">
                  <img
                    src="../assets/photo/prewedding/prewedding-1.jpg"
                    className="img-fluid"
                    alt=""
                    style={{ "min-height": "200px" }}
                  />
                  <div className="gallery-links d-flex align-items-center justify-content-center">
                    <a
                      href="../assets/photo/prewedding/prewedding-1.jpg"
                      title="Gallery 1"
                      className="glightbox preview-link"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="gallery-item h-100">
                  <img
                    src="../assets/photo/prewedding/prewedding-2.jpg"
                    className="img-fluid"
                    alt=""
                    style={{ "min-height": "200px" }}
                  />
                  <div className="gallery-links d-flex align-items-center justify-content-center">
                    <a
                      href="../assets/photo/prewedding/prewedding-2.jpg"
                      title="Gallery 1"
                      className="glightbox preview-link"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="gallery-item h-100">
                  <img
                    src="../assets/photo/prewedding/prewedding-3.jpg"
                    className="img-fluid"
                    alt=""
                    style={{ "min-height": "200px" }}
                  />
                  <div className="gallery-links d-flex align-items-center justify-content-center">
                    <a
                      href="../assets/photo/prewedding/prewedding-3.jpg"
                      title="Gallery 1"
                      className="glightbox preview-link"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="gallery-item h-100">
                  <img
                    src="../assets/photo/prewedding/prewedding-4.jpg"
                    className="img-fluid"
                    alt=""
                    style={{ "min-height": "200px" }}
                  />
                  <div className="gallery-links d-flex align-items-center justify-content-center">
                    <a
                      href="../assets/photo/prewedding/prewedding-4.jpg"
                      title="Gallery 1"
                      className="glightbox preview-link"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
          {gallaryFlag === 1 && (
            <div className="row gy-4 justify-content-center">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="gallery-item h-100">
                  <img
                    src="../assets/photo/maternity/maternity-1.jpg"
                    class="img-fluid"
                    alt=""
                    style={{ "min-height": "200px" }}
                  />
                  <div className="gallery-links d-flex align-items-center justify-content-center">
                    <a
                      href="../assets/photo/maternity/maternity-1.jpg"
                      title="Gallery 1"
                      class="glightbox preview-link"
                    >
                      <i class="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="gallery-item h-100">
                  <img
                    src="../assets/photo/maternity/maternity-2.jpg"
                    class="img-fluid"
                    alt=""
                    style={{ "min-height": "200px" }}
                  />
                  <div className="gallery-links d-flex align-items-center justify-content-center">
                    <a
                      href="../assets/photo/maternity/maternity-2.jpg"
                      title="Gallery 1"
                      class="glightbox preview-link"
                    >
                      <i class="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="gallery-item h-100">
                  <img
                    src="../assets/photo/maternity/maternity-3.jpg"
                    class="img-fluid"
                    alt=""
                    style={{ "min-height": "200px" }}
                  />
                  <div className="gallery-links d-flex align-items-center justify-content-center">
                    <a
                      href="../assets/photo/maternity/maternity-3.jpg"
                      title="Gallery 1"
                      class="glightbox preview-link"
                    >
                      <i class="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
          {gallaryFlag === 2 && (
            <div class="row gy-4 justify-content-center">
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="gallery-item h-100">
                  <img
                    src="assets/photo/baby/baby-1.jpg"
                    class="img-fluid"
                    alt=""
                    style={{ "min-height": "200px" }}
                  />
                  <div class="gallery-links d-flex align-items-center justify-content-center">
                    <a
                      href="assets/photo/baby/baby-1.jpg"
                      title="Gallery 1"
                      class="glightbox preview-link"
                    >
                      <i class="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="gallery-item h-100">
                  <img
                    src="assets/photo/baby/baby-2.jpg"
                    class="img-fluid"
                    alt=""
                    style={{ "min-height": "200px" }}
                  />
                  <div class="gallery-links d-flex align-items-center justify-content-center">
                    <a
                      href="assets/photo/baby/baby-2.jpg"
                      title="Gallery 1"
                      class="glightbox preview-link"
                    >
                      <i class="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="gallery-item h-100">
                  <img
                    src="assets/photo/baby/baby-3.jpg"
                    class="img-fluid"
                    alt=""
                    style={{ "min-height": "200px" }}
                  />
                  <div class="gallery-links d-flex align-items-center justify-content-center">
                    <a
                      href="assets/photo/baby/baby-3.jpg"
                      title="Gallery 1"
                      class="glightbox preview-link"
                    >
                      <i class="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="gallery-item h-100">
                  <img
                    src="assets/photo/baby/family-1.jpg"
                    class="img-fluid"
                    alt=""
                    style={{ "min-height": "200px" }}
                  />
                  <div class="gallery-links d-flex align-items-center justify-content-center">
                    <a
                      href="assets/photo/baby/family-1.jpg"
                      title="Gallery 1"
                      class="glightbox preview-link"
                    >
                      <i class="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </main>
  );
};
