import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FloatingWhatsApp } from "react-floating-whatsapp";

export const Footer = () => {
  const [isload, setLoad] = useState(0);
  const dispatch = useDispatch();
  const isloaded = useRef(null);
  
  return (
    <>
      <footer id="footer" className="footer">
        <div className="container">
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>SK Photography</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            Designed by <a href="#">BootstrapMade</a>
          </div>
        </div>
      </footer>

      <a
        href="#"
        className="scroll-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>

      {/* <div id="preloader">
        <div className="line"></div>
      </div> */}

      <FloatingWhatsApp
        phoneNumber="9545455006"
        accountName="SK Photography"
        allowEsc
        allowClickAway
        notification
        notificationSound
      />
    </>
  );
};
