import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./Home.css";

export const Home = () => {
  return (
    <>
      <section
        id="hero"
        className="hero d-flex flex-column justify-content-center align-items-center"
        data-aos="fade"
        data-aos-delay="1500"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 text-center">
              {/* <h2>
                I'm <span>Sandeep</span> a Professional Photographer from n City
              </h2>
              <p>
                Blanditiis praesentium aliquam illum tempore incidunt debitis
                dolorem magni est deserunt sed qui libero. Qui voluptas amet.
              </p>
              <a href="contact.html" className="btn-get-started">
                Available for hire
              </a> */}
              <Carousel>
                <Carousel.Item>
                  <img
                    src="../assets/photo/prewedding/prewedding-1.jpg"
                    className="slide-css"
                  />
                  <Carousel.Caption>
                    <h3>First slide label</h3>
                    <p>
                      Nulla vitae elit libero, a pharetra augue mollis interdum.
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
                {/* <Carousel.Item>
                  <img
                    src="../assets/photo/prewedding/prewedding-2.jpg"
                    className="slide-css"
                  />
                  <Carousel.Caption>
                    <h3>Second slide label</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                  </Carousel.Caption>
                </Carousel.Item> */}
                <Carousel.Item>
                  <img
                    src="../assets/photo/prewedding/prewedding-4.jpg"
                    className="slide-css"
                  />
                  <Carousel.Caption>
                    <h3>Third slide label</h3>
                    <p>
                      Praesent commodo cursus magna, vel scelerisque nisl
                      consectetur.
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src="../assets/photo/baby/baby-1.jpg"
                    className="slide-css"
                  />
                  <Carousel.Caption>
                    <h3>Fourth slide label</h3>
                    <p>
                      Praesent commodo cursus magna, vel scelerisque nisl
                      consectetur.
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </section>
      <section id="testimonials" class="testimonials" style={{"margin-top": "0px"}}>
        <div class="container">
          <div class="section-header" style={{"text-align": "center", "padding-bottom": "0px"}}>
            <h2></h2>
          </div>
        </div>
      </section>
      <main id="main" data-aos="fade" data-aos-delay="1500">
        <section id="gallery" className="gallery">
          <div className="container-fluid">
            <div className="row gy-4 justify-content-center">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="gallery-item h-100">
                  <img
                    src="../assets/photo/prewedding/prewedding-1.jpg"
                    className="img-fluid"
                    alt=""
                    style={{ "min-height": "200px" }}
                  />
                  <div className="gallery-links d-flex align-items-center justify-content-center">
                    <a
                      href="../assets/photo/prewedding/prewedding-1.jpg"
                      title="Gallery 1"
                      className="glightbox preview-link"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="gallery-item h-100">
                  <img
                    src="../assets/photo/prewedding/prewedding-2.jpg"
                    className="img-fluid"
                    alt=""
                    style={{ "min-height": "200px" }}
                  />
                  <div className="gallery-links d-flex align-items-center justify-content-center">
                    <a
                      href="../assets/photo/prewedding/prewedding-2.jpg"
                      title="Gallery 1"
                      className="glightbox preview-link"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="gallery-item h-100">
                  <img
                    src="../assets/photo/prewedding/prewedding-3.jpg"
                    className="img-fluid"
                    alt=""
                    style={{ "min-height": "200px" }}
                  />
                  <div className="gallery-links d-flex align-items-center justify-content-center">
                    <a
                      href="../assets/photo/prewedding/prewedding-3.jpg"
                      title="Gallery 1"
                      className="glightbox preview-link"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="gallery-item h-100">
                  <img
                    src="../assets/photo/prewedding/prewedding-4.jpg"
                    className="img-fluid"
                    alt=""
                    style={{ "min-height": "200px" }}
                  />
                  <div className="gallery-links d-flex align-items-center justify-content-center">
                    <a
                      href="../assets/photo/prewedding/prewedding-4.jpg"
                      title="Gallery 1"
                      className="glightbox preview-link"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="gallery-item h-100">
                  <img
                    src="../assets/photo/baby/baby-1.jpg"
                    className="img-fluid"
                    alt=""
                    style={{ "min-height": "200px" }}
                  />
                  <div className="gallery-links d-flex align-items-center justify-content-center">
                    <a
                      href="../assets/photo/baby/baby-1.jpg"
                      title="Gallery 1"
                      className="glightbox preview-link"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="gallery-item h-100">
                  <img
                    src="../assets/photo/baby/baby-2.jpg"
                    className="img-fluid"
                    alt=""
                    style={{ "min-height": "200px" }}
                  />
                  <div className="gallery-links d-flex align-items-center justify-content-center">
                    <a
                      href="../assets/photo/baby/baby-2.jpg"
                      title="Gallery 1"
                      className="glightbox preview-link"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="gallery-item h-100">
                  <img
                    src="../assets/photo/baby/baby-3.jpg"
                    className="img-fluid"
                    alt=""
                    style={{ "min-height": "200px" }}
                  />
                  <div className="gallery-links d-flex align-items-center justify-content-center">
                    <a
                      href="../assets/photo/baby/baby-3.jpg"
                      title="Gallery 1"
                      className="glightbox preview-link"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="gallery-item h-100">
                  <img
                    src="../assets/photo/baby/family-1.jpg"
                    className="img-fluid"
                    alt=""
                    style={{ "min-height": "200px" }}
                  />
                  <div className="gallery-links d-flex align-items-center justify-content-center">
                    <a
                      href="../assets/photo/baby/family-1.jpg"
                      title="Gallery 1"
                      className="glightbox preview-link"
                    >
                      <i className="bi bi-arrows-angle-expand"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
