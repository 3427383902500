import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { selectType } from "../Reducers/activityReducer";

export const Header = () => {
  const [isload, setLoad] = useState(0);
  const dispatch = useDispatch();
  const isloaded = useRef(null);

  return (
    <header id="header" className="header d-flex align-items-center fixed-top">
        <div ref={isloaded} style={{ display: "none" }} />
      <div className="container-fluid d-flex align-items-center justify-content-between">
        <a
          href="index.html"
          className="logo d-flex align-items-center  me-auto me-lg-0"
        >
          <i className="bi bi-camera"></i>
          <h1>SK Photography</h1>
        </a>

        <nav id="navbar" className="navbar">
          <ul>
            <li>
            <Link to="/">Home</Link>
            </li>
            <li>
            <Link to="/about">About</Link>
            </li>
            <li className="dropdown">
            <Link to="/gallery">
                <span>Gallery</span>{" "}
                <i className="bi bi-chevron-down dropdown-indicator"></i>
              </Link>
              <ul>
                <li>
                <Link to="/gallery" onClick={() => dispatch(selectType(0))}>Prewedding Shoot</Link>
                </li>
                <li>
                <Link to="/gallery" onClick={() => dispatch(selectType(1))}>Maternity Shoot</Link>
                </li>
                <li>
                <Link to="/gallery" onClick={() => dispatch(selectType(2))}>Baby / Family Shoot</Link>
                </li>
              </ul>
            </li>
            <li>
            <Link to="/services">Services</Link>
            </li>
            <li>
            <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </nav>

        <div className="header-social-links">
          <a href="#" className="twitter">
            <i className="bi bi-twitter"></i>
          </a>
          <a href="#" className="facebook">
            <i className="bi bi-facebook"></i>
          </a>
          <a href="#" className="instagram">
            <i className="bi bi-instagram"></i>
          </a>
          <a href="#" className="linkedin">
            <i className="bi bi-linkedin"></i>
          </a>
        </div>
        <i className="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
        <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>
      </div>
    </header>
  );
};
