import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Provider } from "react-redux";
import { Header } from "./Header/Header";
import { Footer } from "./Footer/Footer";
import { Gallery } from "./Gallery/Gallery";
import { Home } from "./Home/Home";
import { store } from "./store";
import { Services } from "./Services/Services";
import { Contact } from "./Contact/Contact";

function App() {
  return (
    <Provider store={store}>
      <div className="App" id="root">
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Header />
                  <Home />
                  <Footer />
                </>
              }
            />
            <Route
              path="/about"
              element={
                <>
                  <Header />
                  <Home />
                  <Footer />
                </>
              }
            />
            <Route
              path="/gallery"
              element={
                <>
                  <Header />
                  <Gallery />
                  <Footer />
                </>
              }
            />
            <Route
              path="/services"
              element={
                <>
                  <Header />
                  <Services />
                  <Footer />
                </>
              }
            />
            <Route
              path="/contact"
              element={
                <>
                  <Header />
                  <Contact />
                  <Footer />
                </>
              }
            />
          </Routes>
        </Router>
      </div>
    </Provider>
  );
}

export default App;
